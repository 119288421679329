import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './components/theme';
import NavbarComponent from './components/NavbarComponent';
import Footer from './components/Footer';
import CircularProgress from '@mui/material/CircularProgress';

// Lazy load components
const Home = React.lazy(() => import('./components/Home'));
const Contact = React.lazy(() => import('./components/Contact'));
const Impressum = React.lazy(() => import('./components/Impressum'));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <NavbarComponent />
        <Suspense fallback={
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </div>
        }>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/Impressum" element={<Impressum />} />
          </Routes>
        </Suspense>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
